import React from "react";

const Main = () => {
    return (
        <main>
            <p>Howdy! I'm a front end developer based out of NYC currently working for <a href="https://www.rightpoint.com/">Rightpoint</a>.</p>
            <p>In my free time I enjoy playing guitar, exploring the city on my bike, and building npm packages.</p>
            <p>Want to connect? Find me on...</p>
            <ul>
                <li><a class="connection_links" href="https://github.com/brandonsabino">GitHub</a></li>
                <li><a class="connection_links" href="https://www.linkedin.com/in/brandon-sabino-88ba44125">LinkedIn</a></li>
            </ul>
        </main>
    )
}

export default Main;
