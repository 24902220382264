import React from 'react';

const Header = () => {
  return (
    <header>

    </header>
  );
};

export default Header;
