import React from 'react';

const Banner = (props) => {
  const { text } = props;

  return (
    <div style={styles.banner}>
      <h1 style={styles.text}>{text}</h1>
    </div>
  );
};

const styles = {
  banner: {
    textAlign: 'center',
  },
  text: {
    fontSize: '72px',
  },
};

export default Banner;
