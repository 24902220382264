import Header from './Header.js'
import Banner from './Banner';
import Main from './Main.js'
import './App.css';

function App() {
  return (
    <div className="App">
      <Banner text="Brandon Sabino" />
      <Header />
      <Main />
    </div>
  );
}

export default App;
